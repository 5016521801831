<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale"  style="overflow:auto;height:700px;">
            <!-- 顶部导航 -->
            <div class="box-card" >
                 <!-- 按钮 -->
                <!-- <el-row style="height:40px;">
                    <el-button type="primary" class="buttons" @click="exportData">导出</el-button>
                </el-row> -->
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="所属门店" >
                        <el-select v-model="req.s" placeholder="请选择" style="width:120px;">
                            <el-option
                                    v-for="item in statusList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="创建时间">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker 
                                v-model="req.start"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="开始时间">
                                </el-date-picker>
                            </div>
                            |&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker 
                                v-model="req.end"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getList">筛选</el-button>
                    </el-form-item>
                </el-form>
                
                 <!-- 顶部5个banner -->
                <div class="top_banner">
                    <div class="item">
                        <div class="disc">销售额（元）</div>
                        <div class="num">{{setPrice(this.showData.sales_total)}}</div>
                    </div>
                    <div class="item">
                        <div class="disc">销售笔数</div>
                        <div class="num">{{this.showData.sales_count}}<span v-if="this.showData.sales_count === undefined">0</span></div>
                    </div>
                    <div class="item">
                        <div class="disc">销售利润（元）</div>
                        <div class="num">{{setPrice(this.showData.sales_profit)}}</div>
                    </div>
                    <div class="item">
                        <div class="disc">销售退款笔数</div>
                        <div class="num">{{setPrice(this.showData.sales_back_count)}}</div>
                    </div>
                    <div class="item">
                        <div class="disc">销售退款金额（元）</div>
                        <div class="num">{{setPrice(this.showData.sales_back_total)}}</div>
                    </div>
                </div>
                <div style="height:20px;"></div>
                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="500"
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width: 96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    
                    <el-table-column label="创建时间"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.create_time }}</template>
                    </el-table-column>

                    <el-table-column label="客户"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.username }}</template>
                    </el-table-column>

                    <el-table-column label="所属分店"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.shop_name }}</template>
                    </el-table-column>

                    <el-table-column label="销售金额（元）"  min-width="65" align="center">
                        <template slot-scope="scope">{{ setPrice(scope.row.sales_total) }}</template>
                    </el-table-column>

                    <el-table-column label="销售笔数"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.sales_count }}</template>
                    </el-table-column>

                    <el-table-column label="销售利润（元）"  min-width="65" align="center">
                        <template slot-scope="scope">{{ setPrice(scope.row.sales_profit) }}</template>
                    </el-table-column>                    

                    <el-table-column label="退款金额（元）"  min-width="65" align="center">
                        <template slot-scope="scope">{{ setPrice(scope.row.sales_back_total)}}</template>
                    </el-table-column>  
                    <el-table-column label="退款笔数"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.sales_back_count}}</template>
                    </el-table-column>  
                    <el-table-column label="退款利润（元）"  min-width="65" align="center">
                        <template slot-scope="scope">{{ setPrice(scope.row.sales_back_profit)}}</template>
                    </el-table-column>  
                </el-table>
            </div>
            <div style="height:180px;"></div>
        </div>
        <el-dialog
            :modal-append-to-body="false"
            title="导出数据"
            :visible.sync="exportExcelFlag"
            v-if="exportExcelFlag"
            width="60%"
            center>
                <div style="height:50px;width:100%;text-align:center;">
                    <i class="el-icon-loading" v-if="exportStatus===1" style="font-size:50px;color:#000;"></i>
                    <i class="el-icon-success" v-if="exportStatus===2" style="font-size:50px;color:green;"></i>
                </div>
                <div v-if="exportStatus===1" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                    数据正在导出...稍后会自动下载，请留意下载工具
                </div>
                <div v-if="exportStatus===2" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                    数据已导出完毕，本窗口即将关闭
                </div>
        </el-dialog>
        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="添加员工"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="60%"
        center>
            <createShare  :id="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>
        <!-- 页码 -->
        <div class="pageDiv" style="">
            <el-pagination
                style="margin:10px 75px 10px 0;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
            </el-pagination>
        </div>
        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :id="currentId" ></shareList>
        </el-dialog>
    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";

import * as echarts from 'echarts';

export default {
props:{

    },
    inject: ['selectSide'], 
    data(){
        return {
            //上传图片列表地址
            uploadImages: [

            ],
            shopList:[],
            showData:{},
            createShareFlag:false,
            shareListFlag:false,
            currentId:0,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            statusList:[],
            paihang1:[],
            paihang2:[],
            req:{
                start:'',
                end:'',
                page:1,
                limit:30,
                is_today:'',
                is_seven_today:'',
                is_thirty_today:''
            },
            exportStatus: 1,
            exportExcelFlag: false
        }
    },
    components:{
        createShare,shareListFlag
    },
    methods:{
        init(){
            //数据源
            apis.data_analysis_all_source().then(res=>{
                if(tools.msg(res,this)){
                    this.statusList = res.data.shop;
                }
            }).catch(err=>{
                tools.err(err,this);
            });

        },
        

        LineChart(data){

            let series = [];

            for(let i=0;i<data.series.length;i++){
                let obj = {
                    name: data.series[i].name,
                    type: 'line',
                    stack: data.series[i].name,
                    data: data.series[i].data
                };

                series.push(obj);
            }
            //初始化echart
            let option = {
                title: {
                    text: '销售走势'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: data.data
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: data.xAxis
                },
                yAxis: {
                    type: 'value'
                },
                series: series
            };
            //转化数据

            option && echarts.init(document.querySelector('.lineChart')).setOption(option);
        },
        pieChart(data){
            let cate = [];
            for(let i=0;i<data.length;i++){
                let obj = {
                    name: data[i].class_name,
                    value: data[i].num,
                };
                cate.push(obj);
            }
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: '销售占比',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '40',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: cate
                    }
                ]
            };

            option && echarts.init(document.querySelector('.pieChart')).setOption(option);
        },
        //导出Excel
        exportData() {
            this.exportExcelFlag = true;
            apis.sale_report_cate_excel(this.req).then(res => {
                this.exportStatus = 2;
                let blob = new Blob([res]);
                let url = URL.createObjectURL(blob);
                setTimeout(() => {
                    this.exportExcelFlag = false;
                    this.exportStatus = 1;
                }, 2500);
                // 下载该blob链接
                tools.downloadFile(url, document.title + tools.getDateTime() + '.xls');
            }).catch(err => {
                console.log(err);
                // this.exportStatus = 3;
            });
        },
        setDate(field){
            this.reset();
            this.req[field] = 1;
            this.getList();
        },
        setPrice(price){
            return tools.setPrice(price);
        },
        onSubmit(){
            this.getList();
        },
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        reset(){
            this.req = tools.copy(this.$options.data().req);
        },
        getList(){
            // 数据
            apis.data_analysis_detail(this.req).then((res) => {
                if(tools.msg(res,this)){
                    this.showData = res.data.statistics;
                    this.tableData = res.data.data;
                }
            }).catch((err) => {
                tools.err(err,this);
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        shareExit(){
            this.createShareFlag = false;
            this.shareListFlag = false;
        },
        remove(id, key) {
            this.$confirm('确定删除此销售单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                apis.staffDelete({ id: id}).then((info) => {
                    if (info.code == 200) { 
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        edit(id){
            this.createShareFlag = true;
            this.currentId = id;
        }
    },
    created(){
        this.getList();
    },
    mounted(){
        this.init();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
.add_split {
    border-bottom: 1px solid #f0f0f0;
    padding: 15px 0 15px 0;
    margin-bottom: 10px;
    position: relative;
    background: #fafafa;
    text-indent: 10px;
    .leftBar {
        width: 5px;
        height: 45px;
        background: #006EFF;
        position: absolute;
        left: 0px;
        top: 0px;
        display: inline-block;
    }   
}
.top_banner{
    .item{
        width:207px;height:120px;background: #f0f0f0;
        text-indent: 30px;display: inline-block;
        margin:10px 0 0 20px;
    }
    .disc{
        position:relative;top:30px;
    }
    .num{
        position:relative;font-size: 36px;top:48px;
        font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC';
        font-weight: 650;
        color: rgba(0, 110, 255, 0.898039215686275);
    }
}

</style>